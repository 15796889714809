import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { connect, useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Icon,
  ModalContainer,
  ModalHeader,
  PremiumText,
  StockUsage,
  Line,
  ExportFooter,
  TotalPriceContainer,
  Spinner,
  SmartImageContainer,
  ExportModal,
  ExportWrap,
  ModalBody,
  SubtitleToggle,
  InfoWrapper,
  TopWrap,
  ExportExceed,
  PayExportWrapper,
  PayWrap
} from "../../header/header-components";
import { Backdrop } from "../modal-components";
import Typography from "../../../common-components/Typography";
import LabelDropdown from "../../../common-components/LabelDropdown";
import Action from "../../../common-components/Action";
import LabelInputField from "../../../common-components/LabelInputField";
import { STATIC_PATH, API_URL, THUMBNAIL_URL } from "../../../constants/config";
import content from "../../../constants/content";
import { font } from "../../../constants/font";
import { Button as BackButton } from "../../panels/panel-components";
import AccordionComponent from "../../../common-components/Accordion";
import { exportVideo, getCardNonce, vcCall } from "../../../redux/actions/exportUtils";
import { DEFAULT_QUALITY_OPTIONS, PLAN, QUALITY_OPTIONS } from "../../../constants";
import ExportLoader from "./export-loader";
import ProgressBarComponent from "../../../common-components/Progress";
import { setWorkspaceTextFocus } from "../../../redux/actions/appUtils";
import { showUpgrade } from "../../../redux/actions/modalAction";
import openUrl from "../../../helper/openUrl";
import proceedWait from "../../../helper/proceedWait";
import { ProgressiveImageComponent } from "../../workspace/workspace-progressive-image";
import useNotify from "../../../helper/hooks/useNotify";
import { createVersion } from "../../../api/versionUtils";
import vmTheme from "../../../constants/theme";
import Toggle from "../../../common-components/Toggle";
import CustomTooltipComponent from "../../../common-components/CustomTooltip";
import { useCustomCallback } from "../../../helper/hooks/useCustomCallback";
import { computePrice, getPaymentValue, getRenderData, isExeed } from "./export-helper";
import PaymentCard from "./payment-card";
import Restriction from "../../../common-components/Restriction";
import { isEnterPrise, isPaidUser } from "../../../helper/checkUser";

const {
  EXPORT,
  // FILE_TYPE,
  NAME,
  PREMIUM_USED,
  VIEW_ALL,
  EXPORT_SUCCESS,
  PAY_AND_DOWNLOAD,
  PAY_AND_PUBLISH,
  DOWNLOAD,
  TOTAL_AMOUNT,
  UPGRADE,
  PAYMENT_FAILED,
  CONTACT_US,
  ENTER_PROJECT_TITLE,
  EMPTY_PROJECT,
  FAILED_TO_GENERATE_NONCE,
  FAILED_TO_EXPORT_VIDEO,
  FAILED_TO_LOAD_DATA,
  MEDIA_UNAVAILABLE,
  CHANGE,
} = content;

export const HeaderSections = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 0px 0;
`;

const LoaderContainer = styled.div`
  height: 80px;
  width: auto;
`;

export const PremiumSection = (arg) => {
  const { buttonDisable } = arg
  const theme = useSelector((state) => state.app.get("theme"));

  const renderData = getRenderData(arg);

  return (
    <PremiumText>
      {renderData.map(({ mainText, subText }, i) => {
        return (
          <>
            {i ? <Line /> : null}
            <Typography
              className="premiumHeading"
              font={font.bold_12_18}
              content={mainText}
              display="block"
              enableTrim={true}
              width="240px"
              color={vmTheme[theme].exportTextColor}
            />
            <Typography
              className="premiumContent"
              font={font.normalMicro_11}
              color={vmTheme[theme].exportTextColor}
              content={subText}
            />
          </>
        )
      })}
      {buttonDisable && (
        <Typography
          className="premiumContent"
          font={font.normalMicro_11}
          color={vmTheme[theme].exportTextColor}
          content={MEDIA_UNAVAILABLE}
        />
      )}
    </PremiumText>
  );
};

export const GetDropdownData = ({ vc, dropDownDataStates, activeMenu, isShorts, isYoutube = false }) => {
  const { setActiveMenu, setButtonText } = dropDownDataStates;
  const { plan } = useSelector((store) => store.userDetails);

  if (Object.keys(vc).length > 0) {
    const dataTypes = ["images", "videos", "audios"];

    const dropdownData = dataTypes.map((type) => {
      const typeData = vc.stockPurchase[type];
      const stocks = typeData && typeData.stocks;
      return {
        id: type,
        title: `${stocks ? stocks.length : 0} x ${type.charAt(0).toUpperCase() + type.slice(1)}`,
        labelIcon: typeData && typeData.isExceeded ? `${STATIC_PATH}failed-icon.svg` : `${STATIC_PATH}check.svg`,
        subText: plan !== PLAN.LITE && `$${computePrice(typeData?.stocks)}.00`,
        option: stocks ? stocks.map((stock) => ({
          label: stock.name,
          heading: stock.isCredited ? "Available" : "Monthly free credit exceeded",
          contents: stock.isCredited ? "You have credits available" : "Upgrade to Premium plan to get more assets!",
          id: stock.id,
          path: stock.src || `${STATIC_PATH}music-play.svg`,
          checkIcon: stock.isCredited ? `${STATIC_PATH}check.svg` : `${STATIC_PATH}failed-icon.svg`,
          amount: plan !== PLAN.LITE && stock.amount ? `$${stock.amount}` : "",
        })) : [],
      };
    }).filter((item) => item.option.length > 0);
    const isExceeded = isExeed(vc, isShorts, isYoutube);
    let buttonText = DOWNLOAD;
    if (isExceeded) {
      switch (plan) {
        case PLAN.FREE:
        case PLAN.LITE:
          // dropDownDataStates.setIsUpgrade(true);
          buttonText = UPGRADE;
          break;
        case PLAN.TEAM:
        case PLAN.STARTER:
          if (isShorts) {
            // dropDownDataStates.setIsUpgrade(true);
            buttonText = UPGRADE;
          } else {
            buttonText = vc.bgRemovalExceed ? CONTACT_US : isYoutube ? PAY_AND_PUBLISH : PAY_AND_DOWNLOAD;
          }
          break;
        case PLAN.TIER1:
        case PLAN.TIER2:
        case PLAN.TIER3:
        case PLAN.TIER4:
        case PLAN.TIER5:
        case PLAN.ENTERPRISE:
          buttonText = CONTACT_US;
          break;
        default:
          buttonText = DOWNLOAD;
          break;
      }
    }
    if (vc.durationExceed) {
      buttonText = isEnterPrise(plan) ? CONTACT_US : UPGRADE;
    }
    setButtonText(buttonText);

    return dropdownData.map((e, index) => {
      return (
        <AccordionComponent
          title={e.title}
          labelIcon={e.labelIcon}
          key={`${index + e.title}`}
          options={e.option}
          activeMenu={activeMenu}
          subText={e.subText}
          margin="6px 0px"
          clickHandler={(val) => {
            setActiveMenu(val);
          }}
          changeHandler={(label) => label}
        />
      );
    });
  }
  return (
    <LoaderContainer>
      <Spinner />
    </LoaderContainer>
  );
};

const Export = ({
  modalHandler,
  isShowModal,
  projectId,
  projectTitle = "",
  onCloseModal,
  userDetails,
  isShortsPlayer,
  shortId,
  upgrade
}) => {
  const { dimensionName, exportquality } = useSelector((store) => store.userDetails);
  const [resData, setResData] = useState([]);
  const projectDetails = useSelector((store) => store.projectDetails);
  const hasSubtitle = !!projectDetails.get("localSubtitle").size;
  const { warn, success } = useNotify();
  const [isActive, setIsActive] = useState(false);
  const [activeMenu, setActiveMenu] = useState("");
  const [vc, setVc] = useState({});
  const [dimensions, setDimensions] = useState("");
  // const [format, setFormat] = useState(FILE_FORMATS[0].value);
  const [title, setTitle] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [isProgress, setProgress] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [isCard, setIsCard] = useState(false);
  const [assetExceed, setAssetExceed] = useState({ usedStockCount: 0, usedMusicCount: 0 });
  const [buttonText, setButtonText] = useState("");
  const [totalCost, setTotalCost] = useState(0);
  const [progressArray, setProgressArray] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [isUpgrade, setIsUpgrade] = useState(false);
  const theme = useSelector((state) => state.app.get("theme"));
  const [isBurnSubtitle, setIsBurnSubtitle] = useState(false);
  const dispatch = useDispatch();
  const { plan, pm, teamId, cardType, card, credits, exportlength } = useSelector(
    (store) => store.userDetails
  );

  let exportExceedKey = "exportExceed";
  let exportCount = credits.exportcount;
  if (isShortsPlayer) {
    exportExceedKey = "lftsfExportExceed";
    exportCount = credits.lftsfexportcount;
  }

  const onKeyDown = useCustomCallback({
    memoizedCallback: (event) => {
      if (event.keyCode === 27) {
        // 27 is the key code for the Escape key
        modalHandler(event);
      }
    },
  });

  useEffect(() => {
    const data = QUALITY_OPTIONS(dimensionName, plan, exportquality).reverse();
    setResData([...data]);
  }, [dimensionName, plan, exportquality])

  useEffect(() => {
    if (!isBurnSubtitle && hasSubtitle) {
      setIsBurnSubtitle(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasSubtitle]);

  useEffect(() => {
    window.addEventListener("keydown", onKeyDown);

    return () => {
      window.removeEventListener("keydown", onKeyDown);
    };
  }, [onKeyDown]);

  const toggleBurnSubtitle = () => {
    setIsBurnSubtitle(!isBurnSubtitle);
  };

  const countAssetUsage = (res) => {
    let count = 0;
    if (!isEnterPrise(plan)) {
      res.forEach((items) => {
        if (
          items.name !== "Elite Image" &&
          items.name !== "Elite Video" &&
          items.isCredited
        ) {
          count++;
        }
      });
    }
    return count;
  };

  const checkAvailability = (data) => {
    return Object.keys(data).filter(a => a !== 'bgremoval').every((category) => {
      const { stocks } = data[category];
      return stocks.every(({ available }) => available === true);
    });
  };

  useEffect(() => {
    setActiveMenu("");
    setTitle(projectTitle);
    if (isShowModal) {
      setDimensions(DEFAULT_QUALITY_OPTIONS(dimensionName, plan, exportquality));
      const payload = {
        projectId,
        ...(isShortsPlayer && { shortId }),
      };
      vcCall(payload).then((res) => {
        if (res.exportStatus &&
          !(res.exportStatus === "COMPLETED" || res.exportStatus === "FAILED")) {
          setProgress(true);
        } else {
          setProgress(false);
          let usedStockCount = 0;
          let usedMusicCount = 0;
          if (res.stockPurchase.images) {
            usedStockCount += countAssetUsage(res.stockPurchase.images.stocks) + res.stockPurchase.images.usedCreditLimit;
            if (res.stockPurchase.images.isExceeded) {
              res.assetExceed = true;
            }
          }
          if (res.stockPurchase.videos) {
            usedStockCount += countAssetUsage(res.stockPurchase.videos.stocks) + res.stockPurchase.videos.usedCreditLimit;
            if (res.stockPurchase.videos.isExceeded) {
              res.assetExceed = true;
            }
          }
          if (res.stockPurchase.audios) {
            usedMusicCount = countAssetUsage(res.stockPurchase.audios.stocks) + res.stockPurchase.audios.usedCreditLimit;
            if (res.stockPurchase.audios.isExceeded) {
              res.musicAssetExceed = true;
            }
          }
          const imagesCreditLimit = res.stockPurchase.images ? res.stockPurchase.images.totalCreditLimit : 0;
          const videosCreditLimit = res.stockPurchase.videos ? res.stockPurchase.videos.totalCreditLimit : 0;
          const musicCreditLimit = res.stockPurchase.audios ? res.stockPurchase.audios.totalCreditLimit : 0;

          const stockUsageArray = [];

          if (videosCreditLimit) {
            const { stocks, usedCreditLimit } = res.stockPurchase.videos;

            stockUsageArray.push({
              title: content.VIDEO,
              value: (videosCreditLimit - usedCreditLimit - stocks.length),
              max: videosCreditLimit,
            })
          } else {
            stockUsageArray.push({
              title: content.VIDEO,
              value: videosCreditLimit,
              max: videosCreditLimit,
            })
          }

          if (imagesCreditLimit) {
            const { stocks, usedCreditLimit } = res.stockPurchase.images;

            stockUsageArray.push({
              title: content.IMAGE,
              value: (imagesCreditLimit - usedCreditLimit - stocks.length),
              max: imagesCreditLimit,
            })
          } else {
            stockUsageArray.push({
              title: content.IMAGE,
              value: imagesCreditLimit,
              max: imagesCreditLimit,
            })
          }

          if (musicCreditLimit) {
            const { stocks, usedCreditLimit } = res.stockPurchase.audios;

            stockUsageArray.push({
              title: content.MUSIC,
              value: (musicCreditLimit - usedCreditLimit - stocks.length),
              max: musicCreditLimit,
            })
          } else {
            stockUsageArray.push({
              title: content.MUSIC,
              value: musicCreditLimit,
              max: musicCreditLimit,
            })
          }

          setProgressArray(stockUsageArray.filter((item) => item.value !== 0));

          setTotalCost(getPaymentValue(res));

          if (Object.keys(res.stockPurchase).length > 0) {
            const avaliablity = checkAvailability(res.stockPurchase);
            setButtonDisable(!avaliablity);
          }
          const projectDuration = projectDetails.get("duration");
          let durationExceed = false;
          if (shortId) {
            const { end, start } = projectDetails.get("shorts").get(shortId).toJS();
            durationExceed = (exportlength * 60) < end - start;
          } else {
            durationExceed =
              (exportlength * 60) < projectDuration;
          }
          setAssetExceed({
            ...assetExceed,
            usedStockCount,
            usedMusicCount,
            durationExceed,
          });

          res.durationExceed = durationExceed;
          setVc(res);
        }
      }).catch((error) => {
        onCloseModal();
        warn(error.message || FAILED_TO_LOAD_DATA);
      });
    }
    return () => {
      setVc({});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isActive,
    isShowModal,
    projectId,
    projectTitle,
    dimensionName,
    shortId,
    projectDetails,
  ]);

  const renderProject = (nonce = "") => {
    let currentTab = null;
    if (shortId) {
      setTimeout(() => {
        currentTab = window.open(`${API_URL}shorts/0ja5lph20net9ssf`, "_blank");
      }, 2000);
    }
    exportVideo({
      projectId,
      title,
      format: "mp4",
      dimensions,
      plan: vc.plan,
      exportType: "download",
      isWatermark: vc.watermarkExceed ? 1 : 0,
      appMode: "full",
      ppe: `${isExeed(vc) && totalCost ? 1 : 0}`,
      underReview: false,
      nonce,
      burnSubtitle: isBurnSubtitle,
      ...(isShortsPlayer && { shortId }),
    }).then(async (res) => {
      createVersion({ projectId, ...userDetails });
      if (res.exportId) {
        await proceedWait(2000);
        success(EXPORT_SUCCESS);
        if (shortId) {
          currentTab.location.href = `${API_URL}video/${res.exportId}`;
          currentTab.focus();
        } else {
          openUrl(`${API_URL}video/${res.exportId}`, !shortId);
        }
      } else if ((!res.error || res?.showSuccessToast) && res.redirect_url) {
        if (shortId) {
          currentTab.location.href = res.redirect_url;
          currentTab.focus();
        } else {
          openUrl(res.redirect_url, !shortId);
        }
        success(EXPORT_SUCCESS);
      } else if (res.error) {
        if (shortId) {
          currentTab.close();
        }
        warn(res.message || PAYMENT_FAILED);
      }
      setLoading(false);
      modalHandler();
      if (isShortsPlayer) {
        // reloading to update the Credits after export.
        window.location.reload();
      }
    }).catch((err) => {
      warn(err.message || err.errormsg || FAILED_TO_EXPORT_VIDEO);
    });
  };

  const exportPaymentVideo = () => {
    const audios = projectDetails.get("audios").toJS();
    const workspaceItems = projectDetails.get("workspaceItems").toJS();
    setLoading(true);
    if (Object.keys(audios).length || Object.keys(workspaceItems).length) {
      if (!isEnterPrise(plan) && plan !== PLAN.LITE && pm === 0 && totalCost !== 0.0) {
        getCardNonce(teamId).then((res) => {
          renderProject(res.nonce);
        }).catch((err) => {
          warn(err.message || FAILED_TO_GENERATE_NONCE);
        });
      } else {
        renderProject();
      }
    } else {
      warn(EMPTY_PROJECT);
      setLoading(false);
    }
  };

  const exportVideoProject = () => {
    const isExceeded = isExeed(vc, isShortsPlayer);
    const headerText = content.RUN_OUT_OF_CREDITS;
    const subText = content.UPGRADE_TO_ENJOY_CREDITS;

    if (!title) {
      warn(ENTER_PROJECT_TITLE);
    } else if ((plan === PLAN.LITE && isExceeded) || (isShortsPlayer && vc.lftsfExportExceed)) {
      dispatch(showUpgrade({ upgradesource: 'Export-count', headerText, subText, type: vc.durationExceed ? "durationExceed" : "default" }));
    } else if ((isEnterPrise(plan) && isExceeded) || vc.bgRemovalExceed) {
      const upgradesource = isExceeded ? 'Export-count' : 'Background-removal-credits';
      dispatch(showUpgrade({ upgradesource, headerText, subText }));
    } else if (vc.durationExceed) {
      dispatch(showUpgrade({ upgradesource: 'Video-export-length', headerText, subText, type: "durationExceed" }));
    } else {
      exportPaymentVideo();
    }
  };

  const dropDownDataStates = {
    setButtonText(data) {
      setButtonText(data);
    },
    setActiveMenu(data) {
      setActiveMenu(data);
    },
    setIsUpgrade(data) {
      setIsUpgrade(data);
    },
  };

  const projectThumbnail = projectDetails.get("thumbnail");
  const projectThumbSrc = useMemo(() => {
    // memoize src as we are using random number to bypass cdn cache
    let projectThumb = projectThumbnail;
    if (projectThumb === undefined) {
      projectThumb = `${STATIC_PATH}defaultplaceholder.svg`;
    } else {
      projectThumb = `${THUMBNAIL_URL}${projectThumb}?${Math.random()}`;
    }
    return projectThumb;
  }, [projectThumbnail]);

  const exportCost = (vc.export_cost || {})[plan];

  const onResolutionSelect = (resolutionObj) => {
    if (resolutionObj.isUpgrade) {
      dispatch(showUpgrade({ upgradesource: 'Video-export-length', type: "resolutionExceed" }));
      return;
    }
    setDimensions(resolutionObj.value);
  }

  return (
    <ModalContainer
      className="export-tool-bar"
      isShowModal={isShowModal}
      isShorts={isShortsPlayer}
    >
      <Backdrop
        onClick={modalHandler}
        background={
          isShortsPlayer ? vmTheme[theme].overlayBgColor : "transparent"
        }
      />
      <ExportModal isShowModal={isShowModal} isShorts={isShortsPlayer}>
        {isActive ? (
          <ExportWrap>
            <BackButton>
              <img
                src={`${STATIC_PATH}left-arrow.svg`}
                alt="left-arrow"
                onClick={() => setIsActive(false)}
                height="12px"
                width="12px"
              />
              <Typography
                content={PREMIUM_USED}
                font={font.boldRegular_25}
                color={vmTheme[theme].panelPrimaryColor}
                margin="auto 9px"
                cursor="pointer"
                onClick={() => setIsActive(false)}
              />
            </BackButton>
            <ModalBody padding="0px">
              {(vc.assetExceed ||
                vc.musicAssetExceed ||
                vc[exportExceedKey] ||
                vc.durationExceed ||
                vc.bgRemovalExceed ||
                buttonDisable) && (
                  <PremiumSection
                    musicExceed={vc.musicAssetExceed}
                    stockExceed={vc.assetExceed}
                    planType={plan}
                    exportExceed={vc.durationExceed ? false : vc[exportExceedKey]}
                    durationExceed={vc.durationExceed}
                    bgRemovalExceed={vc.bgRemovalExceed}
                  />
                )}
              <GetDropdownData
                vc={vc}
                isShorts={isShortsPlayer}
                dropDownDataStates={dropDownDataStates}
                assetExceed={assetExceed}
                activeMenu={activeMenu}
              />
            </ModalBody>
          </ExportWrap>
        ) : (
          <div>
            {isProgress ? (
              <ExportLoader
                modalHandler={modalHandler}
                exportStatus={vc.exportStatus}
              />
            ) : (
              <div style={{ height: Object.keys(vc).length > 0 ? "auto" : "500px" }} >
                {Object.keys(vc).length > 0 ? (
                  <div>
                    {isCard ? (
                      <PaymentCard />
                    ) : (
                      <>
                        <ModalHeader isShorts={isShortsPlayer}>
                          <Typography
                            content={isShortsPlayer ? DOWNLOAD : EXPORT}
                            font={
                              isShortsPlayer ? font.bold_16 : font.mediumBase_21
                            }
                            color={vmTheme[theme].panelPrimaryColor}
                          />
                          <Icon
                            src={
                              isShortsPlayer
                                ? `${STATIC_PATH}vm-close.svg`
                                : `${STATIC_PATH}close.svg`
                            }
                            alt="close icon"
                            width={isShortsPlayer ? "24px" : "14px"}
                            height={isShortsPlayer ? "24px" : "14px"}
                            margin="auto 0px"
                            transition="all .3s"
                            onClick={modalHandler}
                          />
                        </ModalHeader>
                        <ModalBody
                          padding={isShortsPlayer ? "10px 40px" : "10px 16px"}
                        >
                          {(vc.assetExceed ||
                            vc.musicAssetExceed ||
                            vc[exportExceedKey] ||
                            vc.durationExceed ||
                            vc.bgRemovalExceed ||
                            buttonDisable) && (
                              <PremiumSection
                                musicExceed={vc.musicAssetExceed}
                                stockExceed={vc.assetExceed}
                                buttonDisable={buttonDisable}
                                planType={plan}
                                exportExceed={vc.durationExceed ? false : vc[exportExceedKey]}
                                durationExceed={vc.durationExceed}
                                bgRemovalExceed={vc.bgRemovalExceed}
                              />
                            )}
                          {!isShortsPlayer && (
                            <SmartImageContainer>
                              <ProgressiveImageComponent
                                src={projectThumbSrc}
                                loaderSrc={`${STATIC_PATH}defaultplaceholder.svg`}
                              />
                            </SmartImageContainer>
                          )}
                          {/* Export Name */}
                          <Typography
                            content={NAME}
                            color={vmTheme[theme].sbSecondaryColor}
                            font={font.normalMini}
                          />
                          <LabelInputField
                            placeholder="Untitled video"
                            border={vmTheme[theme].iconsBorder}
                            value={title}
                            onChange={(e) => {
                              setTitle(e.target.value);
                            }}
                            onFocus={() =>
                              dispatch(setWorkspaceTextFocus(true))
                            }
                            onBlur={() =>
                              dispatch(setWorkspaceTextFocus(false))
                            }
                          />
                          {/* Download Resolution */}
                          <Typography
                            innerContent="Select Resolution"
                            color={vmTheme[theme].sbSecondaryColor}
                            font={font.normalMini}
                          />
                          <LabelDropdown
                            position={"static"}
                            border={`1px solid ${vmTheme[theme].iconsBorder}`}
                            options={resData}
                            height="36px"
                            onChange={onResolutionSelect}
                            enableAutoAlign={!isShortsPlayer}
                            optionWidth={isShortsPlayer ? "338px" : "262px"}
                            defaultValue={DEFAULT_QUALITY_OPTIONS(dimensionName, plan, exportquality)}
                          />
                          {(isPaidUser(plan) && vc[exportExceedKey] && exportCost && !vc.durationExceed && !isShortsPlayer) ? <PayExportWrapper>
                            <PayWrap>
                              <img src={`${STATIC_PATH}orange-star.svg`} alt="" />
                              <Typography
                                content={content.PAY_PER_EXPORT}
                                font={font.normalBase}
                                padding={"2px 0px"}
                                color={vmTheme[theme].panelPrimaryColor}
                                cursor="pointer"
                              />
                            </PayWrap>
                            <Typography
                              content={`$${exportCost}`}
                              font={font.boldBase}
                              color={vmTheme[theme].paymentCardTextColor}
                            />
                          </PayExportWrapper> : null}
                          {hasSubtitle && (
                            <>
                              <Typography
                                content={content.SUBTITLE}
                                color={vmTheme[theme].sbSecondaryColor}
                                font={font.normalMini}
                              />
                              <SubtitleToggle>
                                <InfoWrapper>
                                  <Typography
                                    content={content.BURN_SUBTITLES}
                                    color={vmTheme[theme].subTextModal}
                                    font={font.normalMini}
                                  />
                                  <CustomTooltipComponent
                                    tooltipId={"info-tooltip"}
                                    dataTooltip={content.INCLUDE_THE_SUBTITLE}
                                    tooltipPosition="top"
                                    width="253px"
                                    height="58px"
                                    font={font.normal_13}
                                  >
                                    <img
                                      data-tooltip-id={"info-tooltip"}
                                      src={`${STATIC_PATH}i-icon.svg`}
                                      alt="info-icon"
                                    />
                                  </CustomTooltipComponent>
                                </InfoWrapper>
                                <Toggle
                                  value={isBurnSubtitle}
                                  clickHandler={toggleBurnSubtitle}
                                  borderRadius="34px"
                                  height="20px"
                                  width="32px"
                                  toggleColor={
                                    vmTheme[theme].secondaryBorderColor
                                  }
                                />
                              </SubtitleToggle>
                            </>
                          )}
                          {/* Download File Type */}
                          {/* <Typography
      content={FILE_TYPE}
      color={vmTheme[theme].labelTextColor}
      font={font.normalBase}
    />
    <LabelDropdown
      options={FILE_FORMATS}
      onChange={(obj) => {
        setFormat(obj);
      }}
      height="36px"
    /> */}
                          {Object.keys(vc.stockPurchase).length > 0 && (
                            <HeaderSections>
                              <Typography
                                content={PREMIUM_USED}
                                font={font.normalMini}
                              />
                              <Typography
                                content={VIEW_ALL}
                                font={font.normalMicro_11}
                                cursor="pointer"
                                onClick={() => setIsActive(true)}
                              />
                            </HeaderSections>
                          )}
                          <GetDropdownData
                            vc={vc}
                            isShorts={isShortsPlayer}
                            dropDownDataStates={dropDownDataStates}
                            assetExceed={assetExceed}
                            activeMenu={activeMenu}
                          />
                          {progressArray.length > 0 &&
                            Object.keys(vc.stockPurchase).length > 0 ? (
                            <StockUsage>
                              <ProgressBarComponent
                                progressArray={progressArray}
                                plan={plan}
                                onClick={upgrade}
                                enableUpgrade={true}
                                isExport={true}
                              />
                            </StockUsage>
                          ) : !vc.durationExceed ? <ExportExceed>
                            <Typography
                              innerContent={"Export limit"}
                              font={font.mediumMiniBase}
                              display="block"
                              color={vmTheme[theme].panelPrimaryColor}
                              enableTrim={false}
                            />
                            <Restriction
                              type={vc[exportExceedKey] ? "LIMIT_EXCEED" : "DEFAULT"}
                              content={vc[exportExceedKey] ? 'Limit Exceed' : `${exportCount.totalCredits - exportCount.usedCredits} / ${exportCount.totalCredits} left`}
                              top={"0px"}
                              right={"0px"}
                            />
                          </ExportExceed> : null}
                        </ModalBody>
                        <ExportFooter
                          footerPadding={
                            isShortsPlayer ? "1px 40px 40px" : "1px 16px 10px"
                          }
                          isUpgradeStyle={buttonText === UPGRADE}
                        >
                          {plan !== PLAN.LITE && +totalCost && !vc.durationExceed ? (
                            <TotalPriceContainer>
                              <Typography
                                content={TOTAL_AMOUNT}
                                font={font.normalBase}
                                display="block"
                                color={vmTheme[theme].labelTextColor}
                                margin="16px 0 0 0"
                                align="center"
                                onClick={() => setIsActive(true)}
                              />
                              <Typography
                                innerContent={`$${totalCost}.00`}
                                font={font.boldBase}
                                display="block"
                                margin="16px 0 0 0"
                                align="center"
                                onClick={() => setIsActive(true)}
                              />
                            </TotalPriceContainer>
                          ) : null}
                          <Action
                            background={
                              buttonDisable
                                ? vmTheme[theme].buttonDisableColor
                                : buttonText === DOWNLOAD
                                  ? vmTheme[theme].secondaryBorderColor
                                  : vmTheme[theme].actionBgColor
                            }
                            borderRadius="8px"
                            fontColor={vmTheme[theme].polarColor}
                            // isLoading={plan !== PLAN.LITE && isLoading}
                            isLoading={isLoading}
                            margin="16px 0 0 0"
                            onClick={exportVideoProject}
                            height="36px"
                            font={font.boldBase}
                            text={buttonText}
                            isDisabled={buttonDisable}
                          />
                          {/* {!isUpgrade && !vc.stockExceed && plan === PLAN.LITE && (
      <Action
        background={
          buttonDisable
            ? vmTheme[theme].buttonDisableColor
            : vmTheme[theme].polarColor
        }
        borderRadius="4px"
        fontColor={vmTheme[theme].stockActionColor}
        isLoading={isLoading}
        margin="8px 0 0 0"
        showHoverColor={false}
        borderWidth="1px"
        borderColor={vmTheme[theme].paymentBorderColor}
        onClick={exportPaymentVideo}
        height="36px"
        font={font.boldBase}
        text={KEEP_WATERMARK}
        isDisabled={buttonDisable}
      />
    )} */}
                          {pm === 0 && cardType !== "none" && vc.stockExceed && !isEnterPrise(plan) && (
                            <TopWrap>
                              <Typography
                                innerContent={`Pay with - ${cardType} ending ${card}.`}
                                font={font.normalMini_12}
                                display="block"
                                margin="20px 0 0 0"
                                color={vmTheme[theme].panelPrimaryColor}
                                align="center"
                                onClick={() => setIsActive(true)}
                              />
                              <Typography
                                content={CHANGE}
                                font={font.normalMini_12}
                                display="none"
                                color={vmTheme[theme].panelPrimaryColor}
                                margin="20px 0 0 0"
                                align="center"
                                textStyles={{ textDecoration: "underline" }}
                                onClick={() => setIsCard(true)}
                                cursor={"pointer"}
                              />
                            </TopWrap>
                          )}
                        </ExportFooter>
                      </>
                    )}
                  </div>
                ) : (
                  <Spinner />
                )}
              </div>
            )}
          </div>
        )}
      </ExportModal>
    </ModalContainer>
  );
};
GetDropdownData.propTypes = {
  vc: PropTypes.object,
  activeMenu: PropTypes.string,
  dropDownDataStates: PropTypes.object,
  isShorts: PropTypes.bool,
  isYoutube: PropTypes.bool
};
Export.propTypes = {
  modalHandler: PropTypes.func,
  isShowModal: PropTypes.bool,
  projectId: PropTypes.string,
  projectTitle: PropTypes.string,
  onCloseModal: PropTypes.func,
  userDetails: PropTypes.object,
  isShortsPlayer: PropTypes.bool,
  shortId: PropTypes.string,
  upgrade: PropTypes.func,
};

const mapStateToProps = ({ userDetails, app }) => {
  const { projectId, userId } = userDetails;
  return {
    userDetails,
    projectId,
    userId,
    isShortsPlayer: app.get("isShortsPlayer"),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    export: bindActionCreators(exportVideo, dispatch),
    upgrade: bindActionCreators(showUpgrade, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Export);
