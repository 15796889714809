import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import Typography from "../common-components/Typography";
import content from "../constants/content";
import { font } from "../constants/font";
import vmTheme from "../constants/theme";
import { API, API_URL, STATIC_PATH } from "../constants/config";
import { ConfirmationWrap, Image } from "./publish-component";
import Action from "../common-components/Action";
import useApi from "../helper/hooks/useApi";

const PublishSuccess = ({ exportId, onClick }) => {
  const { post } = useApi();
  const theme = useSelector((state) => state.app.get("theme"));

  const [link, setLink] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isDownload, setIsDownload] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    post(`${API_URL}${API.EXPORT_LIST}`, { size: 30, from: 0 }).then((res) => {
      const exportDetail = res.result.filter((exportData) => exportData.exportid === exportId)[0];
      setIsDownload(exportDetail.exporttype === "download");
      if (exportDetail.exporttype === "download") {
        setLink(`${API_URL}record/${exportId}`);
        setIsLoading(false);
      } else {
        setLink(exportDetail.socialpath);
        setIsLoading(false);
      }
    }).catch(() => { });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exportId]);

  return (
    <ConfirmationWrap>
      <Image src={`${STATIC_PATH}success.svg`} alt="success" />
      <Typography
        content={isDownload ? content.YOUR_VIDEO_EXPORTED_SUCCESSFULLY : content.YOUR_VIDEO_PUBLISHED_SUCCESSFULLY}
        font={font.mediumBase_21}
        color={vmTheme[theme].animoTextColor}
        enableTrim={false}
        align={"center"}
      />
      <Action
        text={content.OPEN_LINK}
        color={vmTheme[theme].polarColor}
        background={vmTheme[theme].secondaryBorderColor}
        hoverColor={vmTheme[theme].openHoverColor}
        width={"100%"}
        height="36px"
        padding="2px 0px 6px"
        font={font.mediumBase_21}
        borderRadius={"8px"}
        onClick={() => onClick(link)}
        isLoading={isLoading}
      />
    </ConfirmationWrap>
  );
};

PublishSuccess.propTypes = {
  exportId: PropTypes.string,
  onClick: PropTypes.func
}

export default PublishSuccess;
