import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import Typography from "./Typography";
import { font } from "../constants/font";
import vmTheme from "../constants/theme";

const ActionStyle = styled.div`
  margin-top: ${(props) => props.marginTop};
  margin-bottom: ${(props) => props.marginBottom};
  margin-right: ${(props) => props.marginRight};
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  background: ${(props) => props.background || props.theme.actionBgColor};
  color: ${(props) => props.color || props.theme.polarColor};
  border: ${(props) => `${props.borderWidth} solid ${props.borderColor}`};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: ${(props) =>
    props.isLoading
      ? "progress"
      : props.isDisabled
        ? "not-allowed"
        : "pointer"};
  border-radius: ${(props) => props.borderRadius};
  position: relative;
  ${(props) =>
    props.showHoverColor &&
    css`
      &:hover {
        background-color: ${props.hoverColor || props.theme.actionHoverColor};
        background: ${props.hoverColor};
      }
    `}
  ${(props) =>
    props.hoverEffects &&
    !(props.activeEffects && props.isSelected) &&
    css`
      &:hover {
        ${props.hoverEffects};
      }
    `}
  ${(props) =>
    props.hoverLabelEffects &&
    css`
      &:hover {
        label {
          ${props.hoverLabelEffects};
        }
      }
    `}
  ${(props) =>
    props.isSelected &&
    props.activeEffects &&
    css`
      ${props.activeEffects}
    `}
  ${(props) =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}
  ${(props) =>
    props.icon &&
    css`
      label::before {
        content: url(${props.icon}); 
        margin: 5px;
        width:22px;
        height:22px;
        left: 0px;
        top: ${props.top || "0px"};
        position: relative;
      }
    `}  
  label {
    color: ${(props) => props.labelColor};
  }
  opacity: ${(props) => (props.isDisabled ? 0.5 : 1)};
`;


const Loader = styled.div`
  span {
    float: left;
    width: 8px;
    height: 16px;
    background-color: ${(props) => props.theme.actionLoader};
    margin: 0 2px;
    animation: lodingbars 1.2s infinite ease-in-out;
    @keyframes lodingbars {
      0%,
      40%,
      100% {
        transform: scaleY(0.6);
      }
      100% {
        transform: scaleY(1.2);
      }
    }
    &.bar2 {
      animation-delay: -1.1s;
    }
    &.bar3 {
      animation-delay: -1s;
    }
    &.bar4 {
      animation-delay: -0.9s;
    }
  }
`;

const ButtonLoader = () => (
  <Loader>
    <span className="bar1" />
    <span className="bar2" />
    <span className="bar3" />
    <span className="bar4" />
  </Loader>
);

const Action = (props) => {
  const {
    text,
    children,
    onClick,
    onPointerDown,
    onTileClick,
    fontColor,
    name,
    isLoading,
    showHoverColor,
    hoverColor,
    font,
    enableTrim,
    removeActiveEffects,
    draggable,
    customClass,
    containerRef,
    top,
  } = props;

  const [isSelected, setIsSelected] = useState(false);
  const theme = useSelector((state) => state.app.get('theme'));

  useEffect(() => {
    if (removeActiveEffects) {
      setIsSelected(false);
    }
  }, [removeActiveEffects]);

  return (
    <ActionStyle
      ref={containerRef}
      className={customClass} /* "action-style" */
      draggable={draggable}
      showHoverColor={showHoverColor}
      top={top}
      hoverColor={hoverColor}
      {...props}
      // to ignore console warning for default library text
      onTileClick={undefined}
      isSelected={isSelected}
      onPointerDown={(e) => {
        if (
          typeof onPointerDown === "function" &&
          !props.isDisabled &&
          !isLoading
        ) {
          onPointerDown(e, name);
        }
      }}
      onClick={(e) => {
        if (!props.isDisabled && !isLoading) {
          const clickHandler = onTileClick || onClick;
          if (typeof clickHandler === "function") {
            clickHandler(e, name);
          }
          setIsSelected(!isSelected);
        }
      }}
    >
      {isLoading ? (
        <ButtonLoader />
      ) : (
        <>
          {text && (
            <Typography
              style={{ lineHeight: "normal" }}
              isNone
              color={fontColor || vmTheme[theme].polarColor}
              content={text}
              font={font}
              enableTrim={enableTrim}
            />
          )}
          {children}
        </>
      )}
    </ActionStyle>
  );
};

/**
 * Default Props Value for Action
 */
Action.defaultProps = {
  height: "40px",
  width: "100%",
  borderColor: "transparent",
  borderWidth: "0px",
  text: "",
  children: null,
  isDisabled: false,
  onClick: () => { },
  marginTop: "16px",
  marginBottom: "16px",
  borderRadius: "4px",
  icon: null,
  name: "",
  isLoading: false,
  showHoverColor: true,
  hoverColor: null,
  font: font.normal,
  enableTrim: true,
  draggable: false,
};

/**
 * Initialize Prop Types for Action
 */
Action.propTypes = {
  height: PropTypes.string,
  marginTop: PropTypes.string,
  width: PropTypes.string,
  background: PropTypes.string,
  color: PropTypes.string,
  borderColor: PropTypes.string,
  borderRadius: PropTypes.string,
  borderWidth: PropTypes.string,
  text: PropTypes.string,
  hoverColor: PropTypes.string,
  children: PropTypes.element,
  isDisabled: PropTypes.bool,
  showHoverColor: PropTypes.bool,
  onPointerDown: PropTypes.func,
  onClick: PropTypes.func,
  onTileClick: PropTypes.func,
  icon: PropTypes.string,
  fontColor: PropTypes.string,
  marginBottom: PropTypes.string,
  name: PropTypes.string,
  isLoading: PropTypes.bool,
  font: PropTypes.string,
  enableTrim: PropTypes.bool,
  activeEffects: PropTypes.string,
  removeActiveEffects: PropTypes.bool,
  draggable: PropTypes.bool,
  customClass: PropTypes.string,
  containerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  top: PropTypes.string,
};

export default Action;
