const ActionTypes = {
  SET_PROJECT: "SET_PROJECT",
  SET_USER_DATA: "SET_USER_DATA",
  SET_PROPERTY_PANEL: "SET_PROPERTY_PANEL",
  SET_MODAL: "SET_MODAL",
  SET_EXPAND: "SET_EXPAND",
  UPDATE_TIMELINE: "UPDATE_TIMELINE",
  UPDATE_TIMELINE_COMPLETE: "updateTimelineComplete",
  UNDO_TIMELINE_UPDATE: "UNDO_TIMELINE_UPDATE",
  SET_PROPERTY_DATASOURCE: "SET_PROPERTY_DATASOURCE",
  SET_TEXT_DATASOURCE: "SET_TEXT_DATASOURCE",
  SET_MUSIC_DATASOURCE: "SET_MUSIC_DATASOURCE",
  SET_SOUND_EFFECT_DATASOURCE: "SET_SOUND_EFFECT_DATASOURCE",
  SET_IMAGE_DATASOURCE: "SET_IMAGE_DATASOURCE",
  SET_VIDEO_DATASOURCE: "SET_VIDEO_DATASOURCE",
  SET_GIPHY_DATASOURCE: "SET_GIPHY_DATASOURCE",
  SET_DATASOURCE_BY_CATEGORY: "SET_DATASOURCE_BY_CATEGORY",
  RESET_DATASOURCE_BY_CATEGORY: "RESET_DATASOURCE_BY_CATEGORY",
  SET_TIMELINE_HEIGHT: "SET_TIMELINE_HEIGHT",
  CHANGE_ZOOM: "CHANGE_ZOOM",
  SPLIT_TIMELINE: "SPLIT_TIMELINE",
  DELETE_ITEM: "DELETE_ITEM",
  DUPLICATE_ITEM: "DUPLICATE_ITEM",
  SET_PLAYHEAD_TIME: "SET_PLAYHEAD_TIME",
  SET_INIT_STAGES: "SET_INIT_STAGES",
  SET_SCREENSHOT_STATUS: "SET_SCREENSHOT_STATUS",
  WEB_WORKER_INIT: "WEB_WORKER_INIT",
  FONT_LOADED: "FONT_LOADED",
  SET_WORKSPACE_TEXT_FOCUS: "SET_WORKSPACE_TEXT_FOCUS",
  SAVE_START: "SAVE_START",
  SAVE_DONE: "SAVE_DONE",
  SET_PLAY_ALL: "SET_PLAY_ALL",
  START_PLAY: "START_PLAY",
  PREFETCH_LOADED: "PREFETCH_LOADED",
  UPDATE_VIDEO_BUFFER_STATUS: "UPDATE_VIDEO_BUFFER_STATUS",
  ADD_TWEEN: "ADD_TWEEN",
  UPDATE_LIBRARY_DRAG_ITEM: "UPDATE_LIBRARY_DRAG_ITEM",
  ADD_OBJECT: "ADD_OBJECT",
  ADD_REPLACE_OBJECT: "ADD_REPLACE_OBJECT",
  DELETE_OBJECT: "DELETE_OBJECT",
  SET_WORKSPACE_DRAGGING: "SET_WORKSPACE_DRAGGING",
  SET_SELECTED_ITEMS: "SET_SELECTED_ITEMS",
  SET_CHILDREN_SELECTION: "SET_CHILDREN_SELECTION",
  UPDATE_TEXT_STATUS: "UPDATE_TEXT_STATUS",
  SET_TEXT_OPTIONS: "SET_TEXT_OPTIONS",
  SET_LAST_EDIT_TEXT: "SET_LAST_EDIT_TEXT",
  CHANGE_TEXT: "CHANGE_TEXT",
  BULK_UPDATE_TEXT: "BULK_UPDATE_TEXT",
  UPDATE_TEXT: "UPDATE_TEXT",
  UPDATE_GROUP_TEXT: "UPDATE_GROUP_TEXT",
  UPDATE_ALL_TEXTS: "UPDATE_ALL_TEXTS",
  UPDATE_TEXT_APPLY_ALL_DATA: "UPDATE_TEXT_APPLY_ALL_DATA",
  UPDATE_TEXT_APPLY_ALL_OPTIONS: "UPDATE_TEXT_APPLY_ALL_OPTIONS",
  UPDATE_GROUP_TEXT_COMPLETE: "updateGroupTextComplete",
  SYNC_TEXT: "SYNC_TEXT",
  WRITE_TEXT: "WRITE_TEXT",
  UPDATE_OBJECT: "UPDATE_OBJECT",
  UPDATE_OBJECT_COMPLETE: "updateObjectComplete",
  TOGGLE_CROP: "TOGGLE_CROP",
  UPDATE_TRANSFORM_STATUS: "UPDATE_TRANSFORM_STATUS",
  MOVE_UPDATE: "MOVE_UPDATE",
  MOVE_UPDATE_COMPLETE: "moveUpdateComplete",
  UNDO_MOVE: "UNDO_MOVE",
  RESIZE_UPDATE: "RESIZE_UPDATE",
  ROTATION_UPDATE: "ROTATION_UPDATE",
  BULK_OBJECT_UPDATE: "BULK_OBJECT_UPDATE",
  BULK_OBJECT_UPDATE_COMPLETE: "bulkObjectUpdateComplete",
  APPLY_FLIP: "APPLY_FLIP",
  APPLY_FLIP_INITIAL: "APPLY_FLIP_INITIAL",
  UNDO_FLIP: "UNDO_FLIP",
  APPLY_FLIP_COMPLETE: "applyFlipComplete",
  APPLY_COLOR: "APPLY_COLOR",
  UNDO_COLOR: "UNDO_COLOR",
  APPLY_COLOR_COMPLETE: "applyColorComplete",
  UPDATE_IMPERATIVE_ROTATE: "UPDATE_IMPERATIVE_ROTATE",
  UPDATE_OBJECT_BY_ID: "UPDATE_OBJECT_BY_ID",
  UPDATE_ITEM: "UPDATE_ITEM",
  APPLY_FRAME_CLIP_PLOT: "APPLY_FRAME_CLIP_PLOT",
  APPLY_FRAME_CLIP_PLOT_COMPLETE: "applyFrameClipPlotComplete",
  CANCEL_FRAMING: "CANCEL_FRAMING",
  SELECT_FRAME_CLIP: "SELECT_FRAME_CLIP",
  CROP_IMAGE: "CROP_IMAGE",
  UPDATE_SWAP_HOVER_DROP: "UPDATE_SWAP_HOVER_DROP",
  CHANGE_AUDIO_VOLUME: "CHANGE_AUDIO_VOLUME",
  SET_PROPERTY_WINDOW: "SET_PROPERTY_WINDOW",
  UPDATE_RECENT_COLORS: "UPDATE_RECENT_COLORS",
  SET_COLOR_THEME_DATA: "SET_COLOR_THEME_DATA",
  UPDATE_ACTIVE_FONT_FAMILY: "UPDATE_ACTIVE_FONT_FAMILY",
  UPDATE_ACTIVE_FONT_NAME: "UPDATE_ACTIVE_FONT_NAME",
  UPDATE_FONT_LOAD_STATUS: "UPDATE_FONT_LOAD_STATUS",
  CHOOSE_FONT: "CHOOSE_FONT",
  UPDATE_TEXT_EFFECTS: "UPDATE_TEXT_EFFECTS",
  UNDO_TEXT_EFFECTS: "UNDO_TEXT_EFFECTS",
  SET_VOLUME: "SET_VOLUME",
  SET_FILE_MANAGER: "SET_FILE_MANAGER",
  PASTE_ITEM: "PASTE_ITEM",
  SET_CLIPBOARD_DATA: "SET_CLIPBOARD_DATA",
  ADD_NOTIFICATION: "ADD_NOTIFICATION",
  REMOVE_NOTIFICATION: "REMOVE_NOTIFICATION",
  SET_MOVE_TO: "SET_MOVE_TO",
  SET_UPLOAD_DATA: "SET_UPLOAD_DATA",
  SET_UPLOAD_PROGRESS: "SET_UPLOAD_PROGRESS",
  SET_LOADER: "SET_LOADER",
  SET_PAYMENT_DETAILS: "SET_PAYMENT_DETAILS",
  SET_PROJECT_DETAILS: "SET_PROJECT_DETAILS",
  SET_FAVOURITE: "SET_FAVOURITE",
  SET_FAVOURITE_DATASOURCE: "SET_FAVOURITE_DATASOURCE",
  UPDATE_PROJECT_DATA: "UPDATE_PROJECT_DATA",
  UPDATE_PROJECT_DATA_COMPLETE: "updateProjectDataComplete",
  SET_VERSION_HISTORY: "SET_VERSION_HISTORY",
  SET_PREVIEW_PROJECT: "SET_PREVIEW_PROJECT",
  SET_SWAP_ITEM: "SET_SWAP_ITEM",
  SET_SWAP_INFO: "SET_SWAP_INFO",
  RESET_SWAP: "RESET_SWAP",
  SET_MOBILE_VIEW: "SET_MOBILE_VIEW",
  SET_EXIT_EFFECT_NAME: "SET_EXIT_EFFECT_NAME",
  SET_ENTER_EFFECT_NAME: "SET_ENTER_EFFECT_NAME",
  SHOW_CONTACT_SALES: "SHOW_CONTACT_SALES",
  SHOW_SUPPORT_FORM: "SHOW_SUPPORT_FORM",
  SHOW_UPGRADE_MODAL: "SHOW_UPGRADE_MODAL",
  SET_ANIMO_PLAYER: "SET_ANIMO_PLAYER",
  SET_SHORTS_PLAYER: "SET_SHORTS_PLAYER",
  SET_ANIMO_ID: "SET_ANIMO_ID",
  SET_SHORT_CONTAINER_ID: "SET_SHORT_CONTAINER_ID",
  SET_RESIZE: "SET_RESIZE",
  SET_GLOBAL_VOLUME: "SET_GLOBAL_VOLUME",
  SET_PAUSE: "SET_PAUSE",
  SWITCH_THEME: "SWITCH_THEME",
  SET_ANIMO_FULL_SCREEN: "SET_ANIMO_FULL_SCREEN",
  SET_SUBTITLE_STATUS: "SET_SUBTITLE_STATUS",
  INSERT_SUBTITLE_DATA: "INSERT_SUBTITLE_DATA",
  INSERT_SUBTITLE_DATA_COMPLETE: "insertSubtitleComplete",
  UPDATE_SUBTITLE_DATA: "UPDATE_SUBTITLE_DATA",
  UPDATE_SUBTITLE_DATA_COMPLETE: "updateSubtitleComplete",
  MOVE_SUBTITLE: "MOVE_SUBTITLE",
  UPDATE_INRO_OUTRO_TEXT: "UPDATE_INRO_OUTRO_TEXT",
  SET_DETACH_DATA: "SET_DETACH_DATA",
  RESET_DETACH_DATA: "RESET_DETACH_DATA",
  SET_VIRAL_STYLES: "SET_VIRAL_STYLES",
  SET_BACKDROP_LOADER: "SET_BACKDROP_LOADER",
  SET_DRAGGED_TRANSITION: "SET_DRAGGED_TRANSITION",
  SET_PROJECT_INFO: "SET_PROJECT_INFO",
  ADD_UPLOAD_HISTORY: "ADD_UPLOAD_HISTORY",
  UPDATE_BLOB_ITEM: "UPDATE_BLOB_ITEM",
  SET_SUBTITLE_CALLBACK: "SET_SUBTITLE_CALLBACK",
  SET_LANGUAGES: "SET_LANGUAGES",
  RECONCILE_SUBTITLE_DATA: "RECONCILE_SUBTITLE_DATA",
  SWITCH_SUBTITLE: "SWITCH_SUBTITLE",
  UPDATE_SUBTITLE_LIST: "UPDATE_SUBTITLE_LIST",
  UPDATE_SUBTITLE_LIST_COMPLETE: "updateSubtitleListComplete",
  UNDO_UPDATE_SUBTITLE_LIST: "UNDO_UPDATE_SUBTITLE_LIST",
  TRIGGER_SUBTITLE_RECONCILE_COMPLETE: "triggerSubtitleReconcileComplete",
  SET_UPGRADE_MODAL: "SET_UPGRADE_MODAL",
  SET_CREDITS: "SET_CREDITS",
  SET_UNSPLASH_DATASOURCE: "SET_UNSPLASH_DATASOURCE",
  UPDATE_PANEL_ITEMS: "UPDATE_PANEL_ITEMS",
  SET_PEXELS_DATASOURCE: "SET_PEXELS_DATASOURCE",
  SET_PEXELS_VIDEO_DATASOURCE: "SET_PEXELS_VIDEO_DATASOURCE",
  SET_PIXABAY_DATASOURCE: "SET_PIXABAY_DATASOURCE",
  SET_PIXABAY_VIDEO_DATASOURCE: "SET_PIXABAY_VIDEO_DATASOURCE",
  SET_PRESET_EFFECT: "SET_PRESET_EFFECT",
  SET_AVATAR_DATA: "SET_AVATAR_DATA",
  SHOW_AVATAR_FORM: "SHOW_AVATAR_FORM",
  SHOW_UPLOAD_MODAL: "SHOW_UPLOAD_MODAL",
  RESET_UPLOAD_DATA: "RESET_UPLOAD_DATA",
  SET_FOOTER_ENABLE: "SET_FOOTER_ENABLE"
};

export const SAVE_START_ACTIONS = [
  ActionTypes.UPDATE_TIMELINE,
  ActionTypes.UNDO_TIMELINE_UPDATE,
  ActionTypes.MOVE_UPDATE,
  ActionTypes.DELETE_OBJECT,
  ActionTypes.RESIZE_UPDATE,
  ActionTypes.ROTATION_UPDATE,
  ActionTypes.UPDATE_LIBRARY_DRAG_ITEM,
];
export const SAVE_COMPLETE_ACTIONS = [ActionTypes.UPDATE_TIMELINE_COMPLETE];

export default ActionTypes;
