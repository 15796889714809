import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Typography from "../common-components/Typography";
import vmTheme from "../constants/theme";
import content from "../constants/content";
import { font } from "../constants/font";
import { Container, ScrollContainer, ExportSidebar, ProgressBarWrapper, ProgressBar, Note, Link } from "./publish-component";
import useApi from "../helper/hooks/useApi";
import { phase1Messages, phase2Updates, phase3Updates, queuePhaseMessages } from "../constants";
import { API, API_URL, PAGE } from "../constants/config";
import useNotify from "../helper/hooks/useNotify";
import ExportFailed from "./export-failed";
import PublishSuccess from "./publish-success";
import openUrl from "../helper/openUrl";

const QUEUED = "QUEUED";
const PHASE1 = "PHASE1";
const PHASE2 = "PHASE2";
const PHASE3 = "PHASE3";
const PHASE4 = "PHASE4";
const COMPLETED = "COMPLETED";
const FAILED = "FAILED";


const Progress = () => {
  const { get, post } = useApi();
  const { id: exportId } = useParams();
  const { warn } = useNotify();
  let existingSts;

  const theme = useSelector((state) => state.app.get("theme"));

  const [phase1Content, setPhase1Content] = useState([]);
  const [renderStatusHeading, setRenderStatusHeading] = useState("");
  const [renderStatusSubheading, setRenderStatusSubheading] = useState("");
  const [progress, setProgress] = useState(0);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailed, setIsFailed] = useState(false);

  const updateCompletedstage = () => {
    const payload = {
      exportId,
      export_status: COMPLETED
    }
    post(`${API_URL}${API.GET_SQS_UPDATE}`, payload, true);
  }

  const handleQueuePhase = (data, callback) => {
    const message = data.exporttype === content.GIF
      ? content.GIFT_IS_WAITING
      : content.PROCESSING_YOUR_VIDEO;
    setRenderStatusSubheading(message);
    setRenderStatusHeading(data.exporttype === "youtube" ? content.PUBLISH_IN_PROGRESS : content.RENDER_IN_PROGRESS);

    let index = 0;
    const updatePhase1Content = () => {
      if (index < queuePhaseMessages.length) {
        setProgress(16);
        setPhase1Content(prev => [...prev, queuePhaseMessages[index]]);
        index++;
        setTimeout(updatePhase1Content, 500); // Delay of 500 ms
      } else {
        callback();
      }
    };
    updatePhase1Content();
  }

  const handlePhase1 = (data, callback) => {
    setRenderStatusHeading(content.RENDERING);
    if (data.exporttype === content.GIF) {
      setRenderStatusSubheading(`Generating and capturing ${Math.round(data.duration * 30)} frames from your gif project`);
    }
    let index = 0;
    const updatePhase1Content = () => {
      if (index < phase1Messages.length) {
        const message = phase1Messages[index];
        const formattedMessage = message.replace('$exportId', exportId)
        setProgress(32);
        setPhase1Content(prev => [...prev, formattedMessage]);
        index++;
        setTimeout(updatePhase1Content, 500); // Delay of 500 ms
      } else {
        callback();
      }
    };
    updatePhase1Content();
  };

  const handlePhase2 = (data, callback) => {
    const phase2Messages = [];
    if (data.exporttype === content.GIF) {
      phase2Messages.push(content.STITCH_AUDIO_TO_GIF);
      phase2Messages.push(content.STITCH_ALL_AUDIO_FILES);
    } else {
      phase2Messages.push(content.STITCH_AUDIO_TO_VIDEO);
      phase2Messages.push(content.STITCH_ALL_FINAL_VIDEO);
    }
    setRenderStatusHeading(phase2Messages[0]);
    setRenderStatusSubheading(phase2Messages[1]);
    let index = 0;
    const updatePhase2Content = () => {
      if (index < phase2Updates.length) {
        setPhase1Content(prev => [...prev, phase2Updates[index]]);
        setProgress(48);
        index++;
        setTimeout(updatePhase2Content, 500); // Delay of 500 ms
      } else {
        callback();
      }
    };
    updatePhase2Content();
  };

  const handlePhase3 = (data, callback) => {
    const phase3Messages = [];
    if (data.exporttype === "youtube") {
      phase3Messages.push(content.READY_FOR_YOUTUBE_UPLOAD);
      phase3Messages.push(content.READY_FOR_YOUTUBE_LINK);
    } else if (data.exporttype === "facebook") {
      phase3Messages.push(content.READY_FOR_FACEBOOK_UPLOAD);
      phase3Messages.push(content.READY_FOR_FACEBOOK_LINK);
    } else {
      phase3Messages.push(content.GENERATE_DOWNLOAD_LINK);
      phase3Messages.push(content.GENERATE_FINAL_DOWNLOAD_LINK);
    }
    setRenderStatusHeading(phase3Messages[0]);
    setRenderStatusSubheading(phase3Messages[1]);

    let index = 0;
    const updatePhase2Content = () => {
      if (index < phase3Updates.length) {
        setProgress(80);
        setPhase1Content(prev => [...prev, phase3Updates[index]]);
        index++;
        setTimeout(updatePhase2Content, 500); // Delay of 500 ms
      } else {
        callback();
      }
    };
    updatePhase2Content();
  }

  const handleExportVideo = () => {
    const onSuccess = (response) => {
      const delay = response.status !== content.VT_DOWNLOAD ? 30000 : 5000;

      switch (response.status) {
        case QUEUED:
          if (existingSts !== response.status) {
            handleQueuePhase(response, () => {
              setTimeout(() => handleExportVideo(), delay);
            });
            existingSts = QUEUED;
          } else {
            setTimeout(() => handleExportVideo(), 5000);

          }
          break;
        case PHASE1:
          if (existingSts !== response.status) {
            handlePhase1(response, () => {
              setTimeout(() => handleExportVideo(), delay);
            });
            existingSts = PHASE1;
          } else {
            setTimeout(() => handleExportVideo(), 5000);
          }
          break;
        case PHASE2:
          if (existingSts !== response.status) {
            handlePhase2(response, () => {
              setTimeout(() => handleExportVideo(), delay);
            });
            existingSts = PHASE2;
          } else {
            setTimeout(() => handleExportVideo(), 5000);
          }
          break;
        case PHASE3:
          if (existingSts !== response.status) {
            handlePhase3(response, () => {
              setTimeout(() => handleExportVideo(), delay);
            });
            existingSts = PHASE3;
          } else {
            setTimeout(() => handleExportVideo(), 5000);
          }
          break;
        case PHASE4:
          if (existingSts !== response.status) {
            setProgress(90);
            setTimeout(() => handleExportVideo(), response.exporttype !== content.VT_DOWNLOAD ? 15000 : 5000);
            if (response.exporttype === content.VT_DOWNLOAD) {
              setTimeout(() => updateCompletedstage, 3000);
            }
            existingSts = PHASE4;
          } else {
            setTimeout(() => handleExportVideo(), 5000);
          }
          break;
        case COMPLETED:
          setProgress(100);
          setIsSuccess(true);
          break;
        case FAILED: {
          setIsFailed(true);
          break;
        }
        default:
          setTimeout(() => handleExportVideo(), 5000);
          break;
      }
    };

    const onFailed = () => {
      warn(content.WENT_WRONG);
    };
    const timestamp = new Date().getTime();
    get(`${API_URL}${API.GET_EXPORT_STATUS}?exportId=${exportId}&t=${timestamp}`, {}, false).then((res) => {
      if (!res.error) {
        onSuccess(res);
      } else {
        onFailed();
      }
    }).catch(() => {
      onFailed();
    });
  };

  useEffect(() => {
    if (exportId) {
      handleExportVideo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exportId]);

  const onSupportClick = useCallback(() => {
    openUrl(`${API_URL}${PAGE.SUPPORT}`, true);
  }, []);

  const onOpenLink = (link) => {
    openUrl(link, true);
  }

  if (isSuccess) {
    return <PublishSuccess exportId={exportId} onClick={onOpenLink} />
  }

  if (isFailed) {
    return <ExportFailed onAction={onSupportClick} />
  }

  return (
    <ExportSidebar padding="40px 24px 24px">
      <Typography
        content={renderStatusHeading}
        color={vmTheme[theme].animoHeaderText}
        enableTrim={false}
        font={font.boldRegular_25}
        display={"block"}
        align={"center"}
      />
      <Typography
        content={renderStatusSubheading}
        color={vmTheme[theme].exportLabelColor}
        enableTrim={false}
        font={font.normalMicroLarge_16}
        display={"block"}
        padding={"8px 18px 0px"}
        align={"center"}
      />
      <Container>
        <ScrollContainer>
          {phase1Content.length > 0 && (
            <div>
              {phase1Content.map((text) => (
                <Typography
                  key={text}
                  innerContent={text}
                  color={vmTheme[theme].progressTextColor}
                  font={font.normalMicro_11}
                  display="block"
                  padding="6px 0px"
                />
              ))}
            </div>
          )}
          <ProgressBarWrapper>
            <ProgressBar progress={progress} />
          </ProgressBarWrapper>
        </ScrollContainer>
      </Container>
      <Note>
        You will be notified once the video published on to your YouTube channel. You can also view the file from
        <Link href={`${API_URL}${API.MANAGE_EXPORTS}`}> Manage Exports </Link>section.
      </Note>
    </ExportSidebar>
  );
};

export default Progress;
