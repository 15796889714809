import config from "../constants/config";
import { isVideoOnly } from "../containers/timeline/timeline-helper";

/**
 * @typedef ItemPartial
 * @property {string} type
 * @property {string} subType
 * @property {boolean | undefined} isBlob
 * @property {boolean | undefined} stickerify
 * @property {boolean | undefined} bgremoval
 * @property {string} src
 * @property {string | undefined} animsrc
 * @property {string | undefined} thumb
 * @property {string | undefined} thumbnail
 * @property {string | undefined} expression
 *
 * @param {object} params
 * @param {ItemPartial} params.item
 * @param {number | undefined} params.workspaceWidth should be passed for types like SCR
 * @param {number | undefined} params.workspaceHeight should be passed for types like SCR
 */
export const getWorkspaceItemSource = (params = {}) => {
  const { item, workspaceWidth, workspaceHeight } = params;

  if (!item) {
    return '';
  }

  let srcBasePath = "";
  let thumbBaseBath = "";
  let thumb = item.thumb !== undefined ? item.thumb : item.thumbnail;
  let projectType = "horizontal";
  if (workspaceHeight > workspaceWidth) {
    projectType = "vertical";
  } else if (workspaceHeight === workspaceWidth) {
    projectType = "square";
  }
  thumb = thumb || "";

  if (item.isBlob) {
    srcBasePath = "";
    thumbBaseBath = "";
  } else if (item.stickerify || item.bgremoval) {
    srcBasePath = config.UPLOADS.image_src;
    thumbBaseBath = config.WORKSPACE_LOADER; // set loader gif path
    thumb = "";
  } else if (thumb.startsWith("https://")) {
    // for STOCKVID with full url
    srcBasePath = "";
    thumbBaseBath = "";
  } else if (
    item.subType === "EBG" ||
    item.subType === "GRAD" ||
    item.subType === "GIPHY" ||
    item.subType === "EPROP" ||
    item.subType === "GETTYICON" ||
    item.subType === "CCHR" ||
    item.subType === "ECHR"
  ) {
    srcBasePath = config[item.subType].src;
    thumbBaseBath = config[item.subType].thumb;
  } else if (item.type === "UPIMAGE") {
    srcBasePath = config.UPLOADS.image_src;
    thumbBaseBath = config.UPLOADS.image_thumb;
  } else if (item.type === "UPVIDEO") {
    const srcPath = config.UPLOADS.video_src;
    const thumbPath = config.UPLOADS.video_thumb;
    srcBasePath = srcPath;
    thumbBaseBath = thumbPath;
  } else if (item.type === "IMG") {
    srcBasePath = config.IMG.img_proxy_3;
    thumbBaseBath = config.IMG.img_thumb;
  } else if (item.subType === "OBGIMG" || item.subType === "OBGVID") {
    srcBasePath = config.BG.obgsrc;
    thumbBaseBath = config.BG.obgsrc;
  } else if (item.type === "VIDEO") {
    const srcPath = config.VIDEO.proxy;
    const thumbPath = config.VIDEO.thumb;
    srcBasePath = srcPath;
    thumbBaseBath = thumbPath;
  } else if (item.type === "STOCKVID") {
    srcBasePath = config.STOCKVID.src;
    thumbBaseBath = config.STOCKVID.proxyThumb;
  } else if (
    (item.type === "PEXELS" && item.subType === "VIDEO") ||
    (item.type === "PIXABAY" && item.subType === "VIDEO")
  ) {
    srcBasePath = config[`${item.type}_VIDEOS`].src;
    thumbBaseBath = config[`${item.type}_VIDEOS`].thumb;
  } else if (item.type === "SCR" || item.type === "SCREEN") {
    srcBasePath = `${config[item.type].src}${projectType}/`;
    thumbBaseBath = `${config[item.type].thumb}${projectType}/`;
  } else if (
    item.type === "ZOOM" ||
    item.type === "STOCKIMG" ||
    (item.type === "PEXELS" && item.subType === "IMAGE") ||
    (item.type === "PIXABAY" && item.subType === "IMAGE") ||
    item.type === "UNSPLASH" ||
    item.type === "BG" ||
    item.type === "SHAPE" ||
    item.type === "PROP" ||
    item.type === "CHR" ||
    item.subType === "EMOJI"
  ) {
    srcBasePath = config[item.type].src;
    thumbBaseBath = config[item.type].thumb;
  }

  const source = {
    src: srcBasePath + item.src,
    thumb: thumbBaseBath + thumb,
    fallbackSrc: undefined,
    animsrc: "",
  };

  if (item.animsrc !== undefined) {
    source.animsrc = srcBasePath + item.animsrc;
  }

  // if (item.type === "UPVIDEO" && source.src.includes("/proxy1/")) {
  //   source.fallbackSrc = source.src.split("/proxy1/").join("/");
  // }

  if (item.expression && (item.subType === "CCHR" || item.subType === "CHR")) {
    const [src, extension] = /[^/]*$/.exec(source.src)[0].split(".");
    source.src = source.src.replace(
      /\/[^/]*$/,
      `/${src}-${item.expression}.${extension}`
    );
  }

  return source;
};

/**
 * @param {object} params
 * @param {object} params.item
 * @param {object} params.isFreeUser
 */
export const getAudioSource = (params = {}) => {
  const { item, isFreeUser = true } = params;
  const { type, subType, src } = item;

  if (isVideoOnly(type, subType)) {
    return getWorkspaceItemSource({ item });
  }

  let srcBasePath = "";
  if (
    subType === "UPAUDIO" ||
    subType === "VOICE" ||
    subType === "RECORD" ||
    subType === "TTS"
  ) {
    srcBasePath = config.UPLOADS.audio_src;
  } else if (subType === "SFX") {
    srcBasePath = config.MUSIC.SFX;
  } else if (subType === "BGM" && type === "EAB") {
    // var freeUser =
    //   ["free", "voice pro", "slides pro", "mobile only"].indexOf(
    //     this.props.userDetails.get("plan")
    //   ) === -1
    //     ? false
    //     : true;
    srcBasePath = isFreeUser ? config.EAB.src : config.EAB.paidSrc;
  } else if (subType === "BGM" && type === "SSM") {
    srcBasePath = "";
  } else {
    srcBasePath = config.MUSIC.BGM;
  }

  return {
    src: srcBasePath + src,
  };
};

/**
 * @param {object} params
 * @param {string} params.userId
 * @param {string} params.videoId
 * @param {string} params.type
 * @param {string} params.subType
 * @param {string} params.src
 * @returns {string} thumbstrip url
 */
export const getThumbStripSource = (params = {}) => {
  const { assetId, type, subType, src, thumb, thumbstrip } = params;
  let source = "";
  switch (type) {
    case "STOCKVID":
      if (thumb.startsWith("https://")) {
        source = thumb;
      } else {
        source = `${config.VIDEO.thumbStrip}${assetId}.png`;
      }
      break;
    case "UPVIDEO": {
      const thumbSource = thumbstrip || thumb;
      source = thumbSource.startsWith("https://") ? thumbSource : config.THUMBNAIL_URL + thumbSource;
    }
      break;
    default:
      source = getWorkspaceItemSource({
        item: {
          src,
          thumb,
          type,
          subType,
        },
      }).thumb;
      break;
  }

  return source;
};
